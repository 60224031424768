<template>
    <div class="org-role">
        <el-button
            class="add-role"
            type="primary"
            icon="el-icon-plus"
            @click="handleAdd"
        >
            新增角色
        </el-button>
        <el-table
            border
            stripe
            :data="tableList"
            ref="multipleTable"
            :highlight-current-row="true"
            v-loading="loading"
            height="100%"
        >
            <el-table-column
                label="序号"
                type="index"
                width="80"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="角色编码"
                prop="Role_Code"
                width="200"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="角色名称"
                prop="Role_Name"
                width="250"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="角色描述"
                prop="Desc"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="用户列表"
                prop="UserNames"
                align="center"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                label="操作"
                width="200"
                align="center"
            >
                <template slot-scope="scope">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="授权"
                        placement="top"
                    >
                        <i class="iconfont iconsuo" @click="handleAuth(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="成员维护"
                        placement="top"
                    >
                        <i class="iconfont iconbumen" @click="handleMemberEdit(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="修改"
                        placement="top"
                    >
                        <i class="iconfont iconxiugai" @click="handleEdit(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="删除"
                        placement="top"
                        v-if="buttonPermissions(scope.row)"
                    >
                        <i class="iconfont iconlajitong" @click="handleDel(scope.row)"></i>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <!--添加修改弹框-->
        <el-dialog
            class="dialog-add"
            :title="dialogTitle"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
            width="8rem"
        >
            <el-form ref="formEdit" :model="formData" :rules="rules">
                <el-form-item label="角色名称" prop="RoleName">
                    <el-input placeholder="请输入角色名称" v-model="formData.RoleName"></el-input>
                </el-form-item>
                <el-form-item label="角色编码" prop="RoleCode">
                    <el-input placeholder="请输入角色编码" v-model="formData.RoleCode" :disabled="isDisabled"></el-input>
                </el-form-item>
                <el-form-item label="角色分类" prop="TypeName">
                    <el-input placeholder="请输入角色分类名称" v-model="formData.TypeName" :disabled="isDisabled"></el-input>
                </el-form-item>
                <el-form-item label="角色分类编码" prop="TypeCode">
                    <el-input placeholder="请输入角色分类编码" v-model="formData.TypeCode" :disabled="isDisabled"></el-input>
                </el-form-item>
                <el-form-item label="状态" required>
                    <el-switch
                        v-model="formData.StateBinding"
                        active-color="#0078FB"
                        active-text="启用"
                        :disabled="isDisabled"
                    >
                    </el-switch>
                </el-form-item>
                <el-form-item label="备注信息">
                    <el-input
                        placeholder="请输入备注"
                        type="textarea"
                        v-model="formData.Remarks"
                        :rows="5"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div class="dialog-footer">
                <div class="btn save-btn" @click="handleSaveRole">
                    保存
                </div>
                <div class="btn close-btn" @click="dialogFormVisible = false">
                    关闭
                </div>
            </div>
        </el-dialog>
        <div class="auth-manage-box" v-if="showAuthManage">
            <authManage :role-id="curRole.Role_Id"></authManage>
        </div>
    </div>
</template>

<script>
import authManage from './authManage/index';
export default {
    name: 'org-user',
    components: { authManage },
    data() {
        return {
            tableList: [],
            tenantId: '',

            formData: {
                RoleName: '', // 角色名称
                RoleCode: '', // 角色编码
                TypeName: '', // 角色分类名称
                TypeCode: '', // 角色分类编码
                Remarks: '', // 备注
                State: null, // 状态
                StateBinding: null,
                IsSys: 0, // 是否为系统角色
                IsFixed: 0, // 是否为系统角色
                IsLocked: 0, // 是否锁定
                TenantId: '', // 租户id
            },
            rules: {
                RoleName: [
                    { required: true, message: '请输入角色名称', trigger: 'blur' },
                    { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' },
                ],
                RoleCode: [
                    { required: true, message: '请输入角色编码', trigger: 'blur' },
                    { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' },
                ],
                TypeName: [
                    { required: true, message: '请输入角色分类名称', trigger: 'blur' },
                ],
                TypeCode: [
                    { required: true, message: '请输入角色分类编码', trigger: 'blur' },
                ],
            },
            dialogTitle: '',
            dialogType: '',
            dialogFormVisible: false,

            isDisabled: false,
            isSys: null,

            showAuthManage: false,
            curRole: {}, // 当前操作得角色
        };
    },
    created() {
        this.tenantId = this.$takeTokenParameters('TenantId');
        this.getOrgList();
    },
    methods: {
        // 获取组织列表
        async getOrgList() {
            this.loading = true;
            const data = await this.$axios.get('/interfaceApi/basicplatform/usermenu/get_org');
            this.tableList = data;
            this.loading = false;
        },

        // 跳转到授权
        handleAuth(item) {
            this.curRole = item;
            this.showAuthManage = true;
        },
        // 成员维护
        handleMemberEdit(item) {
            this.$toast(
                {
                    title: true,
                    text: '成员维护',
                    type: 'eject',
                    t_url: 'admin/org/memberEdit',
                    extr: {
                        showCheck: true,
                        roleId: item.Role_Id,
                        // selectId: item.UserIds,
                        sureCallback: this.getOrgList,
                    },
                    viewPosition: '',
                }
            );
        },
        // 新增角色
        handleAdd() {
            this.dialogTitle = '新增角色';
            this.dialogType = 'newRole';
            this.formData = Object.assign({}, this.$options.data().formData);
            this.dialogFormVisible = true;
        },
        // 角色编辑
        async handleEdit(item) {
            this.isSys = item.IsSys;
            if (item.IsFixed === 1 || item.IsLocked === 1) {
                this.isDisabled = true;
                this.dialogTitle = '查看角色';
            } else {
                this.isDisabled = false;
                this.dialogTitle = '编辑角色';
            }
            this.dialogType = 'editRole';
            const roleData = await this.initRoleInfo(item.Role_Id);
            this.formData = Object.assign(this.formData, roleData);
            this.formData.StateBinding = this.formData.State === 0 ? true : false;
            this.dialogFormVisible = true;
        },
        // 删除角色
        handleDel(item) {
            this.$confirm('确认删除当前角色?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(async () => {
                const deleteDate = {
                    RoleIds: [item.Role_Id],
                    TenantId: this.tenantId,
                };
                const data = await this.deleteRoleRequst(deleteDate);
                if (data) {
                    this.$message.success('删除成功');
                    this.getOrgList();
                }
            });
        },

        // 保存角色
        handleSaveRole() {
            this.$refs.formEdit.validate(async valid => {
                if (valid) {
                    let res = null;
                    this.formData.State = this.formData.StateBinding ? 0 : 1;
                    this.formData.TenantId = this.tenantId;
                    if (this.dialogType === 'newRole') {
                        res = await this.confirmNewRole(this.formData);
                    } else if (this.dialogType === 'editRole') {
                        res = await this.confirmEdit(this.formData);
                    }
                    if (res) {
                        this.$message({
                            showClose: true,
                            message: '保存成功！',
                            type: 'success',
                        });
                        this.getOrgList();
                    }
                    this.dialogFormVisible = false;
                }
            });
        },

        // 删除按钮权限
        buttonPermissions(item) {
            // 系统角色，固定角色，已锁定  二级管理员不能删除
            let returnData = true;
            if (item.IsSys === 1 || item.IsFixed === 1 || item.IsLocked === 1) {
                returnData = false;
            }
            return returnData;
        },

        // 新增
        confirmNewRole(formData) {
            return this.$axios.$post('/interfaceApi/platform/tenant_role_manage/create_and_sync', formData, { defEx: true });
        },

        // 编辑初始化角色数据
        initRoleInfo(roleId = '') {
            return this.$axios.$get(`/interfaceApi/platform/tenant_role_manage/${roleId}`, { defEx: true });
        },

        // 编辑
        confirmEdit(formData) {
            return this.$axios.$put('/interfaceApi/platform/tenant_role_manage/modify_and_sync', formData, { defEx: true });
        },

        // 删除角色请求
        deleteRoleRequst(deleteDate) {
            return this.$axios.$delete('/interfaceApi/platform/tenant_role_manage/delete/sync', deleteDate, { defEx: true });
        },
    },
};
</script>

<style lang="stylus" scoped>
    .org-role
        position relative
        height 100%;
        padding 0.1rem;
        background #fff;
        .add-role
            padding 0
            width: 1.22rem;
            height: 0.4rem;
            background: #1577D2;
            border-radius: 0.02rem;
            font-size 0.16rem;
            color #fff;
            position: absolute;
            right: 0.1rem;
            top: -0.47rem;
            z-index: 2;
        .el-table
            /*height calc(100% - 0.95rem)*/
            .iconfont
                padding 0 0.1rem;
                cursor pointer
            .iconsuo:hover
                color #21BC9A
            .iconbumen:hover
                color #FF6600
            .iconxiugai:hover
                color #1577D2
            .iconlajitong:hover
                color #D60110
            >>> th
                background: #EDF0F5;
                border: 1px solid #DEDFE0;
                color #022782
            th,td
                padding 0
        >>>.dialog-add
            .el-dialog__body
                padding: 0.2rem;
            .el-form-item
                margin-bottom: 0.22rem;
                display: flex
                .el-form-item__label
                    width: 1.3rem
                .el-form-item__content
                    flex 1
                    height: auto
            .dialog-footer
                padding 0.2rem 0;
                display flex
                justify-content space-around
                .btn
                    width: 1.3rem;
                    height: 0.36rem;
                    line-height 0.36rem;
                    text-align center
                    border-radius: 0.01rem;
                    cursor pointer
                .save-btn
                    background: #1577D2;
                    color #fff;
                .close-btn
                    border: 0.01rem solid #1577D2;
                    color #1577D2
        .auth-manage-box
            position: absolute;
            left: 0;
            top: -0.6rem;
            width: 100%;
            height: calc(100% + 0.7rem);
            z-index: 99;
</style>
