<!--角色授权管理-->
<template>
    <div class="auth-manage" v-loading="loading">
        <h2 class="role-name">
            当前角色：分校管销售副总
        </h2>
        <el-main class="content">
            <table>
                <thead>
                    <tr>
                        <th>
                            模块
                        </th>
                        <th>
                            功能分类
                        </th>
                        <th>
                            功能
                        </th>
                    </tr>
                </thead>
            </table>
            <div class="list">
                <table
                    cellpadding="0"
                    cellspacing="0"
                >
                    <tbody>
                        <template v-for="(menu) in authList">
                            <tr v-for="(childMenu,childMenuIndex) in menu.Child" :key="childMenu.Id">
                                <td v-if="childMenuIndex==0" :rowspan="menu.Child.length" class="td1">
                                    <el-checkbox
                                        class="reverse-checkbox"
                                        v-model="menu.Is_Checked"
                                        :indeterminate="menu.isIndeterminate"
                                        @change="handleCheckRowAllChange({ menu: menu, checked: $event })"
                                    >
                                        {{ menu.Name }}
                                    </el-checkbox>
                                </td>
                                <td class="td2">
                                    <el-checkbox
                                        class="reverse-checkbox"
                                        v-model="childMenu.Is_Checked"
                                        :indeterminate="childMenu.isIndeterminate"
                                        @change="handleCheckRowAllChange({ menu: childMenu, checked: $event })"
                                    >
                                        {{ childMenu.Name }}
                                    </el-checkbox>
                                </td>
                                <td>
                                    <div class="td-list" v-for="lastMenu in childMenu.Child" :key="'last'+lastMenu.Id">
                                        <el-checkbox
                                            v-model="lastMenu.Is_Checked"
                                            :indeterminate="lastMenu.isIndeterminate"
                                            @change="handleCheckRowAllChange({ menu: lastMenu, checked: $event })"
                                        >
                                            {{ lastMenu.Name }}
                                        </el-checkbox>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>

            <table>
                <tfoot class="footer">
                    <tr>
                        <td>
                            <el-checkbox class="reverse-checkbox" @change="handleCheckAll">
                                全选
                            </el-checkbox>
                        </td>
                        <td colspan="2">
                            <el-button
                                class="save-btn"
                                type="primary"
                                size="small"
                                @click="handleSaveAuth"
                                :loading="saveLoading"
                            >
                                保存设置
                            </el-button>
                            <el-button
                                class="save-btn"
                                size="small"
                                @click="handleBack"
                            >
                                返回
                            </el-button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </el-main>
    </div>
</template>
<script>
export default {
    name: 'auth-manage',
    data() {
        return {
            loading: false,
            Role_Id: '', // 角色id
            authList: [], // 授权列表
            saveLoading: false,
        };
    },
    props: {
        roleId: String,
    },
    created() {
        this.Role_Id = this.roleId;
        this.init();
    },
    methods: {
        init() {
            this.getAuthOrgList();
        },
        // 获取授权列表
        getAuthOrgList() {
            this.loading = true;
            const url = '/interfaceApi/basicplatform/usermenu/get_auth_org?Role_Id=' + this.Role_Id;
            this.$axios.post(url).then(data => {
                if (data) {
                    this.setTreeIndeterminateStatus(data);
                    this.authList = data;
                    this.loading = false;
                }
            }).catch(e => this.$message(e.ErrorCode.Message));
        },


        // 保存授权
        handleSaveAuth() {
            this.saveLoading = true;
            const checkArr = [];
            this.returnCheckTree(this.authList, checkArr);
            const url = '/interfaceApi/basicplatform/usermenu/save_auth_org?Role_Id=' + this.Role_Id;
            const params = {
                menus: checkArr,
                Role_Id: this.Role_Id,
            };
            this.$axios.post(url, params).then(data => {
                if (data) {
                    this.$message.success('保存成功');
                    this.saveLoading = false;
                }
            }).catch(e => {
                this.saveLoading = false;
                this.$message(e.ErrorCode.Message);
            });
        },
        // 获取选中的菜单id
        returnCheckTree(data, checkArr = []) {
            data.forEach(v => {
                if (v.Is_Checked || v.isIndeterminate) {
                    !checkArr.includes(v.Id) && checkArr.push(v.Id);
                }

                if (v.Child && v.Child.length) {
                    this.returnCheckTree(v.Child, checkArr);
                }
            });
            return checkArr;
        },
        // 选中
        handleCheckRowAllChange(data) {
            const { menu, checked } = data;
            // 处理下级
            if (menu.Child && menu.Child.length) {
                this.checkedChildMenu(menu.Child, checked);
            }
            // 处理父级
            if (menu.PId) {
                this.findParent(this.authList, menu.PId);
            }
            menu.isIndeterminate = false;
        },
        // 选中所有
        handleCheckAll(checked) {
            // 格式化授权数据
            const checkAll = list => {
                list.forEach(item => {
                    item.isIndeterminate = false;
                    item.Is_Checked = checked;
                    if (item.Child && item.Child.length) {
                        checkAll(item.Child);
                    }
                });
            };
            checkAll(this.authList);
        },
        checkedChildMenu(menus, checked) {
            menus.forEach(menu => {
                menu.isIndeterminate = false;
                menu.Is_Checked = checked;
                if (menu.Child && menu.Child.length) {
                    this.checkedChildMenu(menu.Child, checked);
                }
            });
        },


        // 设置这一整条线
        findParent(list, PId) {
            list.forEach(k => {
                this.handleList(k, PId);
            });
        },
        // 设置这一整条线具体方法
        handleList(child, PId) {
            let parentCheckedLength = 0;
            let parentIndeterminateLength = 0;
            if (child.Id === PId) {
                child.Child.forEach(Child => {
                    if (Child.isIndeterminate) {
                        parentIndeterminateLength++;
                    } else if (Child.Is_Checked) {
                        parentCheckedLength++;
                    }
                });
                child.Is_Checked = parentCheckedLength === child.Child.length;
                child.isIndeterminate = (parentIndeterminateLength > 0 || parentCheckedLength > 0) && parentCheckedLength < child.Child.length;
                if (child.PId) {
                    this.findParent(this.authList, child.PId);
                }
            } else if (child.Child.length > 0) {
                this.findParent(child.Child, PId);
            }
        },
        // 重新递归设置半选状态
        setTreeIndeterminateStatus(data) {
            data.forEach(v => {
                let parentCheckedLength = 0;
                let parentIndeterminateLength = 0;
                v.Child.forEach(Child => {
                    if (Child.isIndeterminate) {
                        parentIndeterminateLength++;
                    } else if (Child.Is_Checked) {
                        parentCheckedLength++;
                    }
                });
                v.isIndeterminate = (parentIndeterminateLength > 0 || parentCheckedLength > 0) && parentCheckedLength < v.Child.length;
                if (v.isIndeterminate) {
                    v.Is_Checked = false;
                }
                if (v.Child && v.Child.length) {
                    this.setTreeIndeterminateStatus(v.Child);
                }
            });
        },
        // 返回
        handleBack() {
            this.$parent.showAuthManage = false;
        },
    },
};
</script>

<style lang="stylus" scoped>
.auth-manage
    padding 0.1rem
    width 100%
    height 100%
    background #F0F0F0
    position relative
    .role-name
        padding-left 0.2rem;
        margin-top: 0.1rem;
        margin-bottom 0.2rem;
        font-size 0.2rem;
        color #333;
    .content
        width 100%
        height calc(100% - 0.48rem)
        background #fff!important
        padding-bottom 1.6rem;
        .list
            /*height calc(100% - 1rem);*/
            height 100%;
            overflow auto
        table
            table-layout fixed
            width 100%
            border-collapse:collapse;
            border:none;
            thead
                th
                    background #EDF0F5
                    color #022782
                    text-align: center;
                th:nth-child(1)
                    width 1.9rem;
                th:nth-child(2)
                    width 1.9rem;
            tbody
                .td1
                    width 1.9rem;
                    text-align right
                .td2
                    width 1.9rem;
                    text-align right
            th,td
                padding 0.1rem 0.2rem;
                border 1px solid #D7D7D7
                border-collapse collapse
                font-size 0.16rem;
                .td-list
                    display inline-block
                    box-sizing border-box
                    width 3.4rem;
                    margin 0.08rem 0;
                    padding-left 0.2rem;
                    text-align: left;
    .footer
        td
            border 1px solid #D7D7D7
            &:nth-child(1)
                width 1.9rem;
                text-align right;
        .save-btn
            margin 0 0.1rem;
    .reverse-checkbox
        direction: rtl;
        /deep/ .el-checkbox__label
            padding-right 10px;
</style>
